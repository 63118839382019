import Firebase from 'firebase/app';

import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import firebase from 'firebase/app';

const firebaseConfigEquityDSV = {
  apiKey: "AIzaSyAuYG0_CVtLFNYq4Vr3X3Re3lDfGCRooFg",
  authDomain: "ecwl-equity-dsv.firebaseapp.com",
  projectId: "ecwl-equity-dsv",
  storageBucket: "ecwl-equity-dsv.appspot.com",
  messagingSenderId: "701470170074",
  appId: "1:701470170074:web:a375c2fd50f381e02b3ee3",
  measurementId: "G-0M8H8CL3JD"
};

const firebaseConfigP3DSV = {
  apiKey: "AIzaSyCHmdKV3PdNMI3gZZvylBUYOuXnEzRSWKE",
  authDomain: "ecwl-p3-dsv.firebaseapp.com",
  projectId: "ecwl-p3-dsv",
  storageBucket: "ecwl-p3-dsv.appspot.com",
  messagingSenderId: "108742383144",
  appId: "1:108742383144:web:38d0c55bcb0d4d0bd8c71d",
  measurementId: "G-5E2RZVH2T3"
};

const firebaseConfigP1Demo = {
  apiKey: "AIzaSyDcLVG6NpWhr5Lw6iHV8wyO1MqFxo1u-0g",
  authDomain: "ecwl-p1-demo.firebaseapp.com",
  projectId: "ecwl-p1-demo",
  storageBucket: "ecwl-p1-demo.appspot.com",
  messagingSenderId: "253584800882",
  appId: "1:253584800882:web:02ff3f79bd792caf4056eb",
  measurementId: "G-4K3PKPGJHK"
};

const firebaseConfigP3Demo = {
  apiKey: "AIzaSyADz878lqSoGnru00HtM3BkMAy-UFoSiZI",
  authDomain: "ecwl-p3-demo.firebaseapp.com",
  projectId: "ecwl-p3-demo",
  storageBucket: "ecwl-p3-demo.appspot.com",
  messagingSenderId: "205535967882",
  appId: "1:205535967882:web:ed84aced282851476ccb73",
  measurementId: "G-TXG8REBPVS"
};

const firebaseConfigDEMO = {
  apiKey: 'AIzaSyD-Z7fN-AV-gnmIuAFvj43cECDYepRnMHU',
  authDomain: 'cwl-demo.firebaseapp.com',
  databaseURL: 'https://cwl-demo.firebaseio.com',
  projectId: 'cwl-demo',
  storageBucket: 'cwl-demo.appspot.com',
  messagingSenderId: '207854671484',
  appId: '1:207854671484:web:8cecc40d3d67477d2c1bf9',
  measurementId: 'G-MFP2HG8JR6',
};

const firebaseConfigDoacaoOficial = {
  apiKey: 'AIzaSyD7LCZUwQVLyQjMHOzvpsM1wTtPy1KLUFo',
  authDomain: 'cwl-doacao-oficial.firebaseapp.com',
  projectId: 'cwl-doacao-oficial',
  storageBucket: 'cwl-doacao-oficial.appspot.com',
  messagingSenderId: '475121842580',
  appId: '1:475121842580:web:b8007c444effd0db4a3781',
  measurementId: 'G-V0SBHHZ045',
};

const firebaseConfigICoOwner = {
  apiKey: 'AIzaSyDxBC-WTf5yJsl8elq_TxxggDD80lFrv2k',
  authDomain: 'ecwl-icoowner.firebaseapp.com',
  projectId: 'ecwl-icoowner',
  storageBucket: 'ecwl-icoowner.appspot.com',
  messagingSenderId: '618596401311',
  appId: '1:618596401311:web:f4b8c4d87bf5c8e81722aa',
};

const firebaseConfigICoOwnerStage = {
  apiKey: 'AIzaSyCPkzqRuMi9cIsW9riVwSEZgQYNg8HC8Qk',
  authDomain: 'ecwl-icoowner-stage.firebaseapp.com',
  projectId: 'ecwl-icoowner-stage',
  storageBucket: 'ecwl-icoowner-stage.appspot.com',
  messagingSenderId: '515879709351',
  appId: '1:515879709351:web:56ec6b4ff2b3d2d8cd4982',
};

const firebaseConfigDPStage = {
  apiKey: "AIzaSyADme9hzCRWAPxJMwIairlDvHTDhaYoQJs",
  authDomain: "ecwl-dp-stage.firebaseapp.com",
  projectId: "ecwl-dp-stage",
  storageBucket: "ecwl-dp-stage.appspot.com",
  messagingSenderId: "558480588078",
  appId: "1:558480588078:web:916f4fe887056c46d48ebf",
  measurementId: "G-2TSXMW3HXF"
};

const firebaseConfigFinapopStage = {
  apiKey: "AIzaSyDs89EjMna5R3lAmX4psHYpJ53jTG5gJqA",
  authDomain: "ecwl-finapop-stage.firebaseapp.com",
  projectId: "ecwl-finapop-stage",
  storageBucket: "ecwl-finapop-stage.appspot.com",
  messagingSenderId: "1076732296640",
  appId: "1:1076732296640:web:f064ab156e3cd825d05c25",
  measurementId: "G-YQT40JL8TY"
};

const firebaseConfigMcfStage = {
  apiKey: "AIzaSyDouUxwQimXs0LifdXONaEU0FzVUq7fX1w",
  authDomain: "ecwl-mcf-stage.firebaseapp.com",
  projectId: "ecwl-mcf-stage",
  storageBucket: "ecwl-mcf-stage.appspot.com",
  messagingSenderId: "1008228032453",
  appId: "1:1008228032453:web:bb6811b9f73070696675ac",
  measurementId: "G-DKEYMT0413"
};

const firebaseConfigMcfPRD = {
  apiKey: "AIzaSyB5LAr23vQmws6ZY9YelkGRLhSPG7TTdK4",
  authDomain: "ecwl-mcf.firebaseapp.com",
  projectId: "ecwl-mcf",
  storageBucket: "ecwl-mcf.appspot.com",
  messagingSenderId: "1072625164825",
  appId: "1:1072625164825:web:d379ebc6e32ecd61cc4088",
  measurementId: "G-NERP5ZG4R2"
};

const firebaseConfigAgrocorpStage = {
  apiKey: "AIzaSyAvaFJjX0gLw144lczkk935lqIEbb2WSYY",
  authDomain: "ecwl-agrocorp-stage.firebaseapp.com",
  projectId: "ecwl-agrocorp-stage",
  storageBucket: "ecwl-agrocorp-stage.appspot.com",
  messagingSenderId: "837964443808",
  appId: "1:837964443808:web:02eb28cd1868c3ea5459c5",
  measurementId: "G-6ZC3XRJLFR"
};

const firebaseConfigMypartStage = {
  apiKey: "AIzaSyDPJ_8KXSl1QqmkRtkmIKIbaCO-dGPNzro",
  authDomain: "ecwl-mypart-stage.firebaseapp.com",
  projectId: "ecwl-mypart-stage",
  storageBucket: "ecwl-mypart-stage.appspot.com",
  messagingSenderId: "394335535453",
  appId: "1:394335535453:web:8f1e9b0c216fc6ef6568b4",
  measurementId: "G-ZE75MDZJ2L"
};

const firebaseConfigMonefyStage = {
  apiKey: "AIzaSyBJ0BVXoqqObUWJk-RRJZOv02Ru7Szo_xY",
  authDomain: "ecwl-monefy-stage.firebaseapp.com",
  projectId: "ecwl-monefy-stage",
  storageBucket: "ecwl-monefy-stage.appspot.com",
  messagingSenderId: "443525868438",
  appId: "1:443525868438:web:0c2877a11eb13015ed4bb7",
  measurementId: "G-77HV72Q76K"
};

export const initializeFirebaseApp = (buildApp) => {
  let firebaseConfig = {};
  if (process.env.VUE_APP_ENVIRONMENT == 'equity-dsv') {
    firebaseConfig = firebaseConfigEquityDSV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p3-dsv') {
    firebaseConfig = firebaseConfigP3DSV;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p1-demo') {
    firebaseConfig = firebaseConfigP1Demo;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'p3-demo') {
    firebaseConfig = firebaseConfigP3Demo;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'demo') {
    firebaseConfig = firebaseConfigDEMO;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'doacao-oficial') {
    firebaseConfig = firebaseConfigDoacaoOficial;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner') {
    firebaseConfig = firebaseConfigICoOwner;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'icoowner-stage') {
    firebaseConfig = firebaseConfigICoOwnerStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'dp-stage') {
    firebaseConfig = firebaseConfigDPStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'finapop-stage') {
    firebaseConfig = firebaseConfigFinapopStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mcf-stage') {
    firebaseConfig = firebaseConfigMcfStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mcf-prd') {
    firebaseConfig = firebaseConfigMcfPRD;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'agrocorp-stage') {
    firebaseConfig = firebaseConfigAgrocorpStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'mypart-stage') {
    firebaseConfig = firebaseConfigMypartStage;
  }
  else if (process.env.VUE_APP_ENVIRONMENT == 'monefy-stage') {
    firebaseConfig = firebaseConfigMonefyStage;
  }

  Firebase.initializeApp(firebaseConfig);
  Firebase.auth().onAuthStateChanged(() => {
    buildApp();
  });
};

export const database = () => Firebase.firestore();

export const collectionRef = (name) => {
  return firebase.firestore().collection(name);
};

export const currentUser = () => {
  return firebase.auth().currentUser;
};

export const currentUserId = () => {
  const user = firebase.auth().currentUser;
  return user ? user.uid : undefined;
};
