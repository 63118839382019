<script>
import attachmentsService from '@/services/Attachments/attachmentsService';
import { CWLTipoPagamento, CWLInvestimentoDashboard } from '@/store/cwl_enums';
import moment from 'moment-timezone';

import XLSX from 'xlsx';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  name: 'financial-table',
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data',
    },
    campaign: {
      type: Object
    },
    type: {
      type: String,
      default: '',
      description: 'Whether table is striped or hover type',
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes',
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes',
    },
    prazo: {
      type: String,
      default: ''
    }
  },
  components: {DoubleBounce},
  data() {
    return {
      page: 1,
      perPage: 1024,
      pages: [],
      CWLTipoPagamento: CWLTipoPagamento,
      CWLInvestimentoDashboard: CWLInvestimentoDashboard,

      items_exportar: [],
      pessoa: undefined,
      submodalidade: process.env.VUE_APP_SUBTYPE
    };
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    displayedData() {
      return this.paginate(this.data);
    },
  },
  mounted() {
    for ( const item of this.data ) {
      if ( this.pessoa == undefined ) {
        this.pessoa = item.pessoa;
      }

      if ( this.submodalidade == "bacen" ) {
        this.items_exportar.push({
          investidor: item.pessoa.nome,
          codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
          numero_ccb: item.ccb.numero_ccb || '--',
          valor_investimento: (item.item.value * item.item.quantity),
          remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
          valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
          carencia_total_lote: this.prazo
        });
      }
      else if ( this.submodalidade == "588" ) {
        this.items_exportar.push({
          investidor: item.pessoa.nome,
          codigo_operacao: (item.ccb && item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--',
          valor_investimento: (item.item.value * item.item.quantity),
          remuneracao_acumulada: (this.remuneracaoAcumulada(item)),
          valor_remuneracao: (this.remuneracaoAcumulada(item) - (item.item.value * item.item.quantity)),
        });
      }
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.data.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    formatDate(date) {
      return moment(date).tz('America/Sao_Paulo').format('LLL');
    },
    async downloadContratoCCB(item) {
      try {
        let documento = this.pessoa.documento.numero.replace("-", "").replace(".", "").replace(".", "");
        let path = "ContratosCCB/" + this.campaign.id + "/" + documento;
        let filename =  "ContratoCCB_" + documento + "_" + item.ccb.numero_ccb + ".pdf";
        await attachmentsService.downloadAttachment(filename, path);
      }
      catch (e) {
        console.log(e);
      }
    },
    async downloadTermo(termo) {
      try {
        await attachmentsService.downloadAttachment(termo.name, termo.parent.fullPath);
      }
      catch (e) {
        console.log(e);
      }
    },
    remuneracaoAcumulada(order) {
      if ( this.campaign.fields['Encerramento da Oferta'] && this.campaign.fields['taxa_juros'] ) {
        let taxaJurosOferta = Number(this.campaign.fields['taxa_juros'].value);
        let dataEncerramentoOfertaString = this.campaign.fields['Encerramento da Oferta'].value;
        let dataAtual = moment();
        let dataEncerramentoOferta = moment(dataEncerramentoOfertaString, "YYYY-MM-DD");
        var diffMonths = dataAtual.diff(dataEncerramentoOferta, 'months');
        if ( diffMonths < 0 ) {
          diffMonths = diffMonths * -1;
        }
        else if ( diffMonths == 0 ) { // Se a diferença dos meses for 0, não efetua o cálculo e retorna o próprio valor aportado
          return order.item.value;
        }
        const paraCalcularPotencia = (1 + (taxaJurosOferta / 100));
        const diferencaParaCalcular = this.prazo.length > 0 ? Number(this.prazo) : diffMonths;
        const potenciaCalculada = Math.pow(paraCalcularPotencia, diferencaParaCalcular);
        var remuneracaoAcumuladaCalculo = order.item.value * potenciaCalculada;
        return remuneracaoAcumuladaCalculo;
      }
      else {
        return 0;
      }
    },

    download() {
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.xlsx');
    },
  },
  watch: {
    data() {
      this.setPages();
    },
  },
};
</script>

<template>
  <div>
    <table class="table tablesorter" :class="tableClass">
      <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{column}}</th>
        </slot>
      </tr>
      </thead>
      <tbody :class="tbodyClasses">
        <tr v-for="(item, index) in displayedData" :key="index">
          <td v-if="prazo.length > 0">{{ item.pessoa.nome }}</td>
          <td v-if="prazo.length > 0"> {{ (item.ccb.importacao && item.ccb.importacao.operacao && item.ccb.importacao.operacao.operacao && item.ccb.importacao.operacao.operacao.operacao) ? item.ccb.importacao.operacao.operacao.operacao : '--' }} </td>
          <td v-if="prazo.length > 0"> {{ item.ccb.numero_ccb || '--' }} </td>
          <td>{{ (item.item.value * item.item.quantity) | currency }}</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.CartaoCredito && prazo.length == 0">Cartão de Crédito</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Boleto && prazo.length == 0">Boleto</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.Reservado && prazo.length == 0">Reservado</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.TED && prazo.length == 0">TED</td>
          <td v-if="item.tipo_pagamento === CWLTipoPagamento.PIX && prazo.length == 0">PIX</td>
          <td v-if="item.tipo_pagamento === 'PIX' && prazo.length == 0">PIX</td>
          <td v-if="prazo.length == 0">{{ formatDate(item.order_date)  }}</td>
          <td v-if="prazo.length == 0">{{ item.status }}</td>
          
          <td v-if="item.status != 'Pago'"> -- </td>
          <td v-if="item.status == 'Pago'">{{ remuneracaoAcumulada(item) | currency }}</td>

          <td v-if="prazo.length > 0 && item.status == 'Pago'"> {{ ( remuneracaoAcumulada(item) - (item.item.value * item.item.quantity) ) | currency }} </td>
          <td v-if="prazo.length > 0 && item.status != 'Pago'"> -- </td>

          <td v-if="prazo.length > 0"> {{ prazo }} </td>

          <td v-if="prazo.length == 0">
            <i v-if="submodalidade == 'bacen' && item.ccb && item.ccb.numero_ccb && item.ccb.possui_contrato_download" title="Download Contrato CCB" class="tim-icons icon-cloud-download-93" @click="downloadContratoCCB(item)"></i>
            <div v-for="(termo, index) in item.termos" :key="index" style="display: inline; margin-right: 5px;">
              <i v-if="submodalidade == '588'" :title="'Download ' + termo.name" class="tim-icons icon-cloud-download-93" @click="downloadTermo(termo)"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <nav class="table-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="page--"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
            :key="index"
            @click="page = pageNumber"
            :class="{ 'is-active': page === pageNumber }"
          >
            {{ pageNumber }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page < pages.length"
            @click="page++"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </nav>

    <div class="row">
      <div class="export-button">
        <button
          class="btn btn-primary"
          @click="download"
          :disabled="!displayedData.length"
          :hidden="!prazo"
        >
          <i class="tim-icons icon-cloud-download-93"></i>
          Exportar para Excel
        </button>
      </div>
    </div>
  </div>
</template>
