import firebase from 'firebase';
import { withLog } from '../../logger/with_log';
import settingsService from '@/services/Settings/settingsService';
import axios from 'axios';

export default {
  financingRef() {
    return firebase.firestore().collection('financing');
  },

  financingAccountsRef() {
    return firebase.firestore().collection('financing-accounts');
  },

  dynamicFieldsRef() {
    return firebase.firestore().collection('dynamic-fields');
  },

  async findAll() {
    try {
      const snapshot = await this.financingRef().get();
      if (snapshot.empty) {
        return [];
      }

      return snapshot.docs.map((value) => ({
        id: value.id,
        ...value.data(),
      }));
    } catch (error) {
      console.error('financing fnd all error', error);
    }
  },

  getAll() {
    return withLog(() => this.financingRef().get());
  },

  getAllByStatus(status) {
    return withLog(() =>
      this.financingRef().where('status', '==', status).get(),
    );
  },

  getAllByBancarizadorId(bancarizadorId) {
    return withLog(() =>
      this.financingRef().where('bancarizador.id', '==', bancarizadorId).get(),
    );
  },

  getOnlyCreatedBy(pessoa) {
    return withLog(() =>
      this.financingRef()
        .where('users.created_by.email.email', '==', pessoa.email.email)
        .orderBy('dates.created_at', 'desc')
        .get(),
    );
  },

  create(financing) {
    try {
      const doc = this.financingRef().doc();
      const value = {
        ...financing,
        id: doc.id,
      };
      doc.set(value);
      return value;
    } catch (error) {
      console.error('financing service create error', error);
    }
  },

  async findAccountsById(id) {
    try {
      return (await this.financingAccountsRef().doc(id).get()).data();
    } catch (error) {
      console.error('find accounts by id error', error);
    }
  },

  async findAccountTokenByIdAndKind(id, kind) {
    try {
      if (['beneficiary', 'platform', 'agent'].indexOf(kind) !== -1) {
        const financingAccounts = await this.findAccountsById(id);
        return financingAccounts[kind].token;
      } else if (kind === 'marketplace') {
        return await settingsService.getMarketplaceToken();
      }
    } catch (error) {
      throw new Error('Não foi possível consultar os dados da conta.');
    }
  },

  delete(financing) {
    withLog(() => this.financingRef().doc(financing).delete());
  },

  async findById(id) {
    try {
      return (await this.financingRef().doc(id).get()).data();
    } catch (error) {
      console.error('financing servic find by id error', error);
      throw Error('Não foi possível consultar os dados da campanha.');
    }
  },

  get(id) {
    return withLog(() => this.financingRef().doc(id).get());
  },

  update(id, financing) {
    return withLog(() => this.financingRef().doc(id).update(financing));
  },

  getDynamicFields(modalidade) {
    return withLog(() =>
      this.dynamicFieldsRef()
        .where('template', '==', modalidade)
        .orderBy('ordenacao', 'asc')
        .get(),
    );
  },

  async atualizarRepasseRecebiveis(financingId) {
    const endpoint = process.env.VUE_APP_ENDPOINT_WS + 'subAccountUpdate';
    try {
      let payload = {
        financingId: financingId
      };
      await axios.post(endpoint, payload).then(r => r.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
