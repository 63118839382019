import firebase from 'firebase';
const pdf2base64 = require('pdf-to-base64');
import { withLog } from '../../logger/with_log';

export default {
  storageRef(path) {
    return firebase.storage().ref(path);
  },

  tipoDocumentoRef() {
    return firebase.firestore().collection('perfil_tipo_documento');
  },
  empresaTipoDocumentoRef() {
    return firebase.firestore().collection('empresa_tipo_documento');
  },

  async getEmpresaTipoDocumentos() {
    return withLog(async () => {
      const snapshot = await this.empresaTipoDocumentoRef().orderBy('nome', 'asc').get();
      return snapshot.docs.map((e) => ({
        ...e.data(),
        id: e.id,
      }));
    });
  },

  async getTipoDocumentos() {
    return withLog(async () => {
      const snapshot = await this.tipoDocumentoRef().orderBy('nome', 'asc').get();
      return snapshot.docs.map((e) => ({
        ...e.data(),
        id: e.id,
      }));
    });
  },

  async addNewAttachment(attachment, fileName, storage = 'Standard') {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');
    const response = await operationalAttachment
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },

  async getAttachments(storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);

    const response = await operationalAttachments.listAll();

    return response;
  },

  async getUserDocuments(storage, user) {
    const investorDocumentAttachs = this.storageRef(storage);

    const response = await investorDocumentAttachs.child(user).listAll();

    return response;
  },

  async getTermosByPath(path) {
    const storageDocuments = this.storageRef(path);
    const response = await storageDocuments.listAll();
    return response;
  },

  async deleteAttachment(file, storage = 'Standard', user = undefined) {
    const operationalAttachments = this.storageRef(storage);

    if ( user ) {
      await operationalAttachments.child(user).child(file).delete();
    }
    else {
      await operationalAttachments.child(file).delete();
    }
  },

  async downloadAttachment(file, storage = 'Standard', type = undefined) {
    const operationalAttachments = this.storageRef(storage);

    operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          const a = document.createElement('a');

          a.href = window.URL.createObjectURL(xhr.response);
          a.download = file;
          if ( type ) {
            a.download = file + type;
          }
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();
        };
        xhr.open('GET', url);
        xhr.send();
      });
  },

  async base64Attachment(file, storage = 'Standard') {
    const operationalAttachments = this.storageRef(storage);
    
    return await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(async function (url) {
        
        return await pdf2base64(url)
            .then(response => {
              return response;
            })
            .catch( error => {
              console.log(error);
            })
      });
    
  },

  async urlAttachment(file, storage = 'Standard') {
    let urlAtt = '';
    const operationalAttachments = this.storageRef(storage);
    await operationalAttachments
      .child(file)
      .getDownloadURL()
      .then(function (url) {
        urlAtt = url;
      });

    return urlAtt;
  },

  async addNewDocumentAttachment(attachment, fileName, storage, user) {
    const operationalAttachment = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await operationalAttachment
      .child(user)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },

  async addNewFinancingImageAttachment(
    attachment,
    fileName,
    storage,
    financingId,
  ) {
    const attach = this.storageRef(storage);

    const fileNameAdjusted = fileName.replace(/\//g, '_');

    const response = await attach
      .child(financingId)
      .child(fileNameAdjusted)
      .put(attachment);

    return response;
  },
};
